

export default {
	getRolesWhile(parameters) {
		const { modulename, urlinfo, aksifungsi, bool } = parameters
		let userinfo = JSON.parse(localStorage.getItem('userInfo')).menu

		let Parents = userinfo.find(o => o.MenuModule === modulename)
		let Child = Parents.Child.find(o => o.MenuModule === urlinfo)

		if (Child !== undefined) {
			let regex = new RegExp(aksifungsi) 	// Create a regex object (a constructor).
			let filtered = Child.Aksi.filter(item => regex.test(item.AksiFungsi))	// Tests for a match.

			let status = ''
			if (bool === true) { // jika butuh return boolean
				if (filtered.length > 0) { status = true }
				return status
			}

			if (bool === undefined) {
				if (filtered.length > 0) { status = filtered[0].AksiFungsi }
				return status
			}
		}

		return 'null'
	}
}